import React from 'react'
import classNames from 'classnames'
import CircularProgress, {
  CircularProgressProps,
} from '@material-ui/core/CircularProgress'
import styles from './BasicSpinner.module.scss'

export type BasicSpinnerProps = {
  className?: string
  size?: number | 'lg' | 'sm' | 'xs'
} & CircularProgressProps

const sizes: { [key: string]: number } = {
  lg: 40,
  sm: 20,
  xs: 10,
}

const BasicSpinner = (props: BasicSpinnerProps) => {
  const { className = '', size = 'lg', ...other } = props

  return (
    <CircularProgress
      {...other}
      className={classNames([className, styles.root])}
      size={(typeof size === 'string' && sizes[size]) || size}
    />
  )
}

export default BasicSpinner
