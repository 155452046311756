import React from 'react'
import classNames from 'classnames'
import { get, map, size } from 'lodash'
import Text from 'Components/texts/Text'
import styles from './InputErrors.module.scss'
import { useTranslation } from 'gatsby-plugin-react-i18next'

export type InputErrorsType = null | { [id: string]: string }

export type InputErrorsProps = {
  className?: string
  errors: InputErrorsType
}

const InputErrors = (props: InputErrorsProps) => {
  const { className = '', errors } = props

  const { t } = useTranslation()

  if (!size(errors)) {
    return null
  }

  return (
    <div className={classNames([styles.root, className])}>
      {map(errors, (value, id) => {
        return !value ? null : (
          <div key={`Error_${id}`} className={styles.error}>
            <Text className={styles.text} variant="xs">
              {get(t('validation', { returnObjects: true }), value, value)}
            </Text>
          </div>
        )
      })}
    </div>
  )
}

export default InputErrors
