export type HttpErrorObject = {
  status: number
  message: string
  isCancelled: boolean
}

export default class HttpResponseError extends Error {
  status: number

  message: string

  isCancelled: boolean

  responseData: undefined | Record<string, unknown>

  constructor(
    status: number,
    message: string,
    isCancelled = false,
    responseData?: Record<string, unknown>,
  ) {
    super(message)

    this.name = this.constructor.name
    this.status = status
    this.message = message
    this.isCancelled = isCancelled
    this.responseData = responseData
  }

  serialize = (): HttpErrorObject => {
    return {
      status: this.status,
      message: this.message,
      isCancelled: this.isCancelled,
    }
  }
}
