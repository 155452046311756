/*
 * Network Utilities
 */

export function networkLog(
  type: string,
  method: string,
  url: string,
  success: boolean | null,
  ...args: Array<any>
): void {
  if (process.env.NODE_ENV !== 'production') {
    console.log(
      `%c[${type}][${method}](${url})`,
      // eslint-disable-next-line no-nested-ternary
      `color: ${success === true ? 'green' : success === false ? 'red' : 'blue'}`,
      ...args,
    )
  }
}
