import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import Touchable from 'Components/touchables/Touchable'
import Logo from 'Components/Logo/Logo'
import { usePageScrollPosition } from 'Utils/common'
import styles from './AnimatedLogo.module.scss'

export type AnimatedLogoProps = {
  className?: string
  collapsed: boolean
}

const AnimatedLogo = (props: AnimatedLogoProps) => {
  const { className = '', collapsed = false } = props
  const timer = useRef<NodeJS.Timeout | null>(null)
  const [isScrolling, setIsScrolling] = useState(false)

  usePageScrollPosition(
    ({ currPos }) => {
      setIsScrolling(true)
      timer.current && clearTimeout(timer.current)
      timer.current = setTimeout(() => {
        setIsScrolling(false)
      }, 200)
    },
    [],
    100,
  )

  return (
    <div
      className={classNames([
        styles.root,
        className,
        collapsed && styles.collapsed,
        isScrolling && styles.isScrolling,
      ])}
    >
      <Logo className={styles.logo} />
      <Touchable className={styles.touchable} link="/" styling="clear" />
    </div>
  )
}

export default AnimatedLogo
