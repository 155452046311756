import React, { ComponentType, forwardRef, Ref, SVGAttributes, useCallback } from 'react'
import { SvgIconComponent } from '@material-ui/icons'
import classNames from 'classnames'
import styles from './SvgIcon.module.scss'

export type SvgIconType = ComponentType<SVGAttributes<SVGElement>> | SvgIconComponent

export type SvgIconProps = {
  className?: string
  iconClassName?: string
  Icon: SvgIconType
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void
}

const SvgIcon = (props: SvgIconProps, ref: Ref<any>) => {
  const { className = '', iconClassName = '', Icon, onClick, ...other } = props

  const _onClick = useCallback(
    (event: React.SyntheticEvent<HTMLDivElement>) => {
      if (onClick) {
        event.stopPropagation()
        onClick(event)
      }
    },
    [onClick],
  )

  return (
    <div
      {...other}
      ref={ref}
      role="button"
      tabIndex={-1}
      className={classNames([
        styles.root,
        className,
        { [styles.clickable]: Boolean(onClick) },
      ])}
      onClick={_onClick}
    >
      <Icon className={iconClassName} />
    </div>
  )
}

export default forwardRef(SvgIcon)
