import type { ElementType, ReactNode, Ref } from 'react'
import React, { forwardRef } from 'react'
import classNames from 'classnames'
import styles from './Row.module.scss'

const cn = require('classnames/bind')
const cx = cn.bind(styles)

export type RowProps = {
  className?: string
  children: ReactNode
  vertical?: 'top' | 'center' | 'bottom' | 'line' | 'stretch' | 'spaced'
  horizontal?: 'left' | 'center' | 'right' | 'stretch' | 'spaced'
  wrap?: boolean
  Component?: ElementType
}

const Row = (props: RowProps, ref: Ref<any>) => {
  const {
    className = '',
    children = null,
    vertical = 'center',
    horizontal = 'left',
    wrap = false,
    Component = 'div',
    ...other
  } = props

  return (
    <Component
      {...other}
      ref={ref}
      className={classNames([styles.root, className, cx(vertical, horizontal, { wrap })])}
    >
      {children}
    </Component>
  )
}

export default forwardRef(Row)
