import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import { isFinite, size } from 'lodash'
import type { InputErrorsType } from 'Components/inputs/InputErrors/InputErrors'
import SvgIcon, { SvgIconType } from 'Components/SvgIcon/SvgIcon'
import { MenuItem, TextField, TextFieldProps } from '@material-ui/core'
import ChevronIcon from 'Assets/icon-chevron-down-small.inline.svg'
import InputErrors from 'Components/inputs/InputErrors/InputErrors'
import styles from './Input.module.scss'

export const INPUT_TYPES = {
  text: 'text',
  textArea: 'text_area',
  email: 'email',
  number: 'number',
  phone: 'tel',
}

type InputType = HTMLInputElement | HTMLTextAreaElement

export type InputCallbackData = {
  name?: string
  event?: null | React.SyntheticEvent<InputType>
  value: any
}

export type SelectOption = {
  value: string | number
  label: string
  [key: string]: any
}

type Merge<M, N> = Omit<M, Extract<keyof M, keyof N>> & N

type Value = string | number | undefined

export type InputProps = Merge<
  TextFieldProps,
  {
    theme?: 'base' | 'alt'
    name?: string
    value: Value | Value[] | SelectOption | SelectOption[]
    label?: React.ReactNode
    autoFocus?: boolean
    selectTextOnFocus?: boolean
    disabled?: boolean
    className?: string
    placeholder?: string
    onChange?: (data: InputCallbackData) => void
    onFocus?: (data: InputCallbackData) => void
    onBlur?: (data: InputCallbackData) => void
    onKeyPress?: (data: InputCallbackData) => void
    errors?: InputErrorsType
    hideErrors?: boolean
    tabIndex?: number
    options?: SelectOption[]
    multiple?: boolean
    iconEnd?: null | SvgIconType
  }
>

const Input = (props: InputProps) => {
  const {
    theme = 'base',
    name = '',
    value = '',
    label = '',
    disabled = false,
    className = '',
    autoFocus = false,
    selectTextOnFocus = false,
    onFocus = null,
    onChange,
    onBlur = null,
    onKeyPress = null,
    errors,
    hideErrors = false,
    tabIndex = 1,
    options,
    multiple,
    rows = 6,
    iconEnd,
    type,
    ...other
  } = props

  const clickable = Boolean(other && other.onClick)
  const isSelect = size(options) > 0
  const isMultiline = type === INPUT_TYPES.textArea

  const inputRef = useRef<InputType>(null)
  const [focused, setFocused] = useState(false)

  const _onChange = useCallback(
    (event: React.ChangeEvent<InputType>, child?: { props: SelectOption }) => {
      !disabled &&
        onChange &&
        onChange({
          name,
          event,
          value: multiple && !child?.props?.value ? [] : event?.target?.value || '',
        })
    },
    [disabled, onChange, name, multiple],
  )

  const _onFocus = useCallback(
    (event: React.FocusEvent<InputType>) => {
      setFocused(true)
      onFocus && onFocus({ name, event, value: event.target.value })
      selectTextOnFocus && event.target.select()
    },
    [onFocus, selectTextOnFocus, name],
  )

  const _onBlur = useCallback(
    (event: React.FocusEvent<InputType>) => {
      setFocused(false)
      onBlur && onBlur({ name, event, value: event.target.value })
    },
    [onBlur, name],
  )

  const _onKeyPress = useCallback(
    (event: React.KeyboardEvent<InputType>) => {
      onKeyPress && onKeyPress({ name, event, value: event.currentTarget.value })
    },
    [onKeyPress, name],
  )

  // componentDidMount
  useEffect(() => {
    if (autoFocus && inputRef && !disabled) {
      inputRef.current && inputRef.current.focus()
    }
  }, [])

  const renderTextField = (_props: Partial<TextFieldProps>) => (
    <TextField
      {..._props}
      {...other}
      value={value}
      onBlur={_onBlur}
      onFocus={_onFocus}
      // @ts-ignore
      onKeyPress={_onKeyPress}
      label={label}
      disabled={disabled}
      focused={isSelect ? false : focused}
      error={size(errors) > 0}
      autoFocus={autoFocus}
      tabIndex={tabIndex}
      multiline={isMultiline}
      rows={rows}
      type={type}
      color="primary"
    />
  )

  return (
    <div
      className={classNames([
        styles.root,
        className,
        theme === 'alt' ? styles.alt : styles.base,
        {
          [styles.clickable]: clickable,
          [styles.withIcon]: Boolean(iconEnd),
          [styles.isFocused]: focused,
          [styles.isMultiline]: isMultiline,
          [styles.isFilled]:
            typeof value === 'number' ? isFinite(value) : size(value) > 0,
        },
      ])}
    >
      {renderTextField({
        onChange: _onChange,
        select: isSelect,
        SelectProps: {
          multiple,
          MenuProps: {
            classes: { list: styles.selectList },
          },
        },
        InputProps: {
          endAdornment: (clickable || isSelect || iconEnd) && (
            <SvgIcon
              className={classNames([styles.icon, !iconEnd && styles.selectIcon])}
              Icon={iconEnd || ChevronIcon}
            />
          ),
        },
        children:
          options &&
          options.map((o: SelectOption) => (
            <MenuItem key={o.value} value={o.value}>
              {o.label}
            </MenuItem>
          )),
      })}
      {!hideErrors && errors && <InputErrors className={styles.errors} errors={errors} />}
    </div>
  )
}

export default memo<InputProps>(Input)
