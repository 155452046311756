/*
 * Requests
 */

import HttpClient from 'Utils/network/HttpClient'
import { withRecaptcha } from 'Utils/network/recaptcha'

export type RequestCreateOrderData = {
  name?: string
  contact: string
  text?: string
}

export type RequestCreateOrderResponse = {
  id: number
  name: string
  contact: string
  text?: string
  created_at: string
  updated_at: string
}

export const requestCreateOrder = (data: RequestCreateOrderData) => {
  return withRecaptcha().then((token: string) => {
    return HttpClient.request<RequestCreateOrderResponse>(
      {
        path: '/orders',
        method: 'POST',
      },
      null,
      {
        ...data,
        token,
      },
    )
  })
}
