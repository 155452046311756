import React, { useEffect, useCallback, useRef, useState, MouseEvent } from 'react'
import { Helmet } from 'react-helmet'
import classNames from 'classnames'
import { findLastIndex } from 'lodash'
import cn from 'classnames/bind'
import { PATHS } from 'Utils/routing'
import { usePageScrollPosition } from 'Utils/common'
import Row from 'Components/Row/Row'
import Button from 'Components/touchables/Button'
import { useI18next } from 'gatsby-plugin-react-i18next'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'
import Envelope from 'Assets/icon-envelope.inline.svg'
import Menu from 'Assets/icon-menu.inline.svg'
import { useMenu } from 'Components/layout/Menu'
import AnimatedLogo from 'Components/Logo/AnimatedLogo'
import styles from './Header.module.scss'

const cx = cn.bind(styles)

export type HeaderTheme = 'base' | 'alt'

export type HeaderProps = {
  className?: string
  theme?: HeaderTheme
}

const ANIMATE_OFFSET = 50

const Header = (props: HeaderProps) => {
  const { className = '', theme = 'base' } = props

  const { languages, i18n, changeLanguage } = useI18next()

  const { isMenuOpen, setMenuState } = useMenu()

  const [collapsed, setCollapsed] = useState(false)
  const [open, setOpen] = useState(false)

  const buttonTheme = theme === 'alt' && !isMenuOpen ? 'base' : 'alt'
  const resultCollapsed = collapsed && !isMenuOpen

  const anchorRef = useRef<HTMLDivElement>(null)

  const onMenuItemClick = useCallback(
    (language: string) => {
      changeLanguage(language)
      setOpen(false)
    },
    [setOpen, changeLanguage],
  )

  const toggle = useCallback(() => setOpen((p) => !p), [setOpen])

  const onClose = useCallback(
    (event: MouseEvent<Document>) => {
      if (anchorRef.current?.contains(event.target as HTMLElement)) {
        return
      }

      setOpen(false)
    },
    [setOpen],
  )

  usePageScrollPosition(({ currPos }) => {
    setCollapsed(currPos.y <= -ANIMATE_OFFSET)
  })

  return (
    <header
      className={classNames([
        styles.root,
        className,
        cx(isMenuOpen ? 'base' : theme), // Не работает isMenuOpen в продакшене
        'mui-fixed', // Помогает избежать "дёргание" шапки
        { [styles.collapsed]: resultCollapsed },
      ])}
    >
      <AnimatedLogo
        className={classNames([styles.logo, 'header-logo'])}
        collapsed={resultCollapsed}
      />
      <Row className={styles.buttons}>
        <Button
          className={classNames([styles.button, styles.langButton])}
          title={i18n.resolvedLanguage}
          variant="outline"
          theme={buttonTheme}
          onClick={toggle}
          ref={anchorRef}
        />
        <Popper
          className={styles.popper}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="bottom"
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'top' }}>
              <Paper>
                <ClickAwayListener onClickAway={onClose}>
                  <MenuList autoFocusItem>
                    {languages.map((lng) => (
                      <MenuItem
                        key={lng}
                        selected={lng === i18n.resolvedLanguage}
                        onClick={() => onMenuItemClick(lng)}
                      >
                        {lng.toUpperCase()}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
        <Button
          className={classNames([styles.button, styles.mailButton])}
          iconStart={Envelope}
          variant="outline"
          theme={buttonTheme}
          link={PATHS.contacts}
        />
        <Button
          className={classNames([styles.button, styles.menuButton])}
          iconStart={Menu}
          variant="outline"
          theme={buttonTheme}
          onClick={() => setMenuState(!isMenuOpen)}
        />
      </Row>
    </header>
  )
}

export function HeaderThemeOnScrollChanger({
  themes,
  elementsSelector = 'section',
}: {
  themes: string[]
  elementsSelector?: string
}) {
  const [theme, setTheme] = useState<string>('')
  const elements = useRef<HTMLElement[]>([])
  const headerLogo = useRef<HTMLElement | null>(null)

  usePageScrollPosition(({ currPos }) => {
    const logo = headerLogo?.current
    if (elements.current.length > 0 && logo) {
      const logoTop = logo.offsetTop + logo.offsetHeight / 2
      // Getting last section index
      const index = findLastIndex(elements.current, (i) => {
        return i.offsetTop <= Math.abs(currPos.y) + logoTop
      })
      // Setting theme
      setTheme(themes[index])
    }
  })

  useEffect(() => {
    elements.current = Array.from(
      document.querySelectorAll<HTMLElement>(elementsSelector),
    )
    headerLogo.current = document.querySelector('.header-logo')
  }, [])

  return (
    <Helmet>
      <html data-theme={theme} />
    </Helmet>
  )
}

export default Header
