import React, { useEffect, useMemo, useState } from 'react'
import classNames from 'classnames'
import type { TooltipProps as CoreTooltipProps } from '@material-ui/core'
import CoreTooltip from '@material-ui/core/Tooltip'
import styles from './Tooltip.module.scss'

export type TooltipProps = {
  className?: string
  disabled?: boolean
  textAlign?: 'left' | 'center' | 'right'
  isInvert?: boolean
} & CoreTooltipProps

const Tooltip = (props: TooltipProps) => {
  const {
    className = '',
    title,
    children,
    placement = 'bottom',
    disabled,
    textAlign = 'center',
    isInvert = false,
    ...other
  } = props
  const [open, setOpen] = useState(false)

  const classes = useMemo(
    () => ({
      popper: styles.popper,
      tooltip: classNames([
        styles.tooltip,
        className,
        styles[textAlign],
        { [styles.invert]: isInvert },
      ]),
    }),
    [],
  )

  useEffect(() => {
    disabled && setOpen(false)
  }, [disabled])

  if (!title) {
    return children
  }

  return (
    <CoreTooltip
      disableFocusListener
      enterTouchDelay={0}
      {...other}
      open={open}
      title={title}
      classes={classes}
      placement={placement}
      onOpen={() => !disabled && setOpen(true)}
      onClose={() => setOpen(false)}
    >
      {children}
    </CoreTooltip>
  )
}

export default Tooltip
