/*
 * API Utils
 */

import { ImageApi, ImageLocal } from 'Typings/api'

export const apiURL = (path?: string) => {
  return `${process.env.GATSBY_API_URL || ''}${path || ''}`
}

export const IMAGES_SIZE = {
  large: 'large',
  medium: 'medium',
  small: 'small',
  thumbnail: 'thumbnail',
}

const imageSizes = [
  IMAGES_SIZE.large,
  IMAGES_SIZE.medium,
  IMAGES_SIZE.small,
  IMAGES_SIZE.thumbnail,
]

/**
 * Возвращает ссылку на изображение, хранящееся на стороне API
 */
export const apiImageURL = (image?: ImageApi): string => {
  return !image?.url ? '' : apiURL(image?.url)
}

/**
 * Возвращает ссылку на локально загруженное изображение
 */
export const localImageUrl = (image: ImageLocal) => {
  return image?.localFile?.publicURL
}

export const prepareApiTime = (time?: string): string => {
  return time?.substr(0, 5) || '?'
}

export const withNewLines = (text: string) => {
  return text.replace('\\', '\n')
}
