import React from 'react'
import classNames from 'classnames'
import Header, { HeaderTheme } from 'Components/layout/Header'
import Footer, { FooterProps } from 'Components/layout/Footer'
import styles from './Layout.module.scss'

export type LayoutProps = {
  className?: string
  children: React.ReactNode
  headerTheme?: HeaderTheme
  footerProps?: FooterProps
}

const Layout = ({ className = '', children, headerTheme, footerProps }: LayoutProps) => {
  return (
    <main className={classNames([styles.root, className])}>
      <Header theme={headerTheme} />
      {children}
      <Footer {...footerProps} />
    </main>
  )
}

export default Layout
