import React from 'react'
import classNames from 'classnames'
import LogoSVG from 'Assets/logo.inline.svg'
import styles from './Logo.module.scss'

export type LogoProps = {
  className?: string
}

const Logo = (props: LogoProps) => {
  const { className = '' } = props

  return <LogoSVG className={classNames([styles.root, className])} />
}

export default Logo
