import React, { forwardRef, Ref, useCallback } from 'react'
import { Link, GatsbyLinkProps } from 'gatsby'
import { isFunction } from 'lodash'
import classNames from 'classnames'
import type { TooltipProps } from 'Components/popups/Tooltip/Tooltip'
import Tooltip from 'Components/popups/Tooltip/Tooltip'
import styles from './Touchable.module.scss'

type CustomGatsbyLink = Omit<GatsbyLinkProps<Record<string, unknown>>, 'ref'>

export type TouchableProps = {
  className?: string
  onClick?: (event: React.SyntheticEvent<HTMLDivElement>) => void
  disabled?: boolean
  styling?: 'alt' | 'clear'
  link?: string
  linkProps?: CustomGatsbyLink
  href?: string
  target?: '_blank' | '_self'
  children?: React.ReactNode
  tabIndex?: number
  innerRef?: React.Ref<any>
  tooltip?: React.ReactNode
  tooltipProps?: TooltipProps
}

const Touchable = forwardRef((props: TouchableProps, ref: Ref<any>) => {
  const {
    className = '',
    onClick = null,
    disabled = false,
    styling,
    link = null,
    linkProps = null,
    href = null,
    target = '_blank',
    children = null,
    tabIndex = 0,
    ...other
  } = props

  const _className = classNames(styles.root, className, {
    [styles.disabled]: disabled,
    [styles.isLink]: Boolean(href || link),
    [styles.isClear]: styling === 'clear',
    [styles.isAlt]: styling === 'alt',
  })

  const _onClick = useCallback(
    (e: React.SyntheticEvent<HTMLDivElement>) => {
      e.stopPropagation()

      if (!disabled && onClick && isFunction(onClick)) {
        onClick(e)
      }

      return true
    },
    [disabled, onClick],
  )

  if (href) {
    return (
      <a
        {...other}
        ref={ref}
        className={_className}
        tabIndex={tabIndex}
        href={href}
        target={target}
      >
        {children}
      </a>
    )
  }

  if (link && !disabled) {
    return (
      <Link ref={ref} {...other} {...linkProps} className={_className} to={link}>
        {children}
      </Link>
    )
  }

  return (
    <div
      {...other}
      ref={ref}
      role="link"
      tabIndex={tabIndex}
      className={_className}
      onClick={_onClick}
    >
      {children}
    </div>
  )
})

const TouchableWithTooltip = (props: TouchableProps, ref: Ref<any>) => {
  const { tooltip, tooltipProps } = props

  if (tooltip) {
    return (
      <Tooltip title={tooltip} {...tooltipProps}>
        <Touchable {...props} />
      </Tooltip>
    )
  }

  return <Touchable ref={ref} {...props} />
}

export default forwardRef(TouchableWithTooltip)
