import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Canceler,
} from 'axios'
import { stringify } from 'qs'
import { get } from 'lodash'
import { networkLog } from './utils'
import HttpResponseError from './HttpResponseError'

export type GetCancel = (cancel: Canceler) => void

export type HttpRequestAction = {
  apiKey?: string
  path: string
  method?: 'GET' | 'POST' | 'PATCH' | 'DELETE'
  getCancel?: GetCancel
}

export type HttpResponseSuccess<T> = {
  success: boolean
  responseData: T
}

type HttpSuccessResponseData = { message?: string } | []

type HttpFailResponseData = {
  errors: Array<{ title: string }>
  message: string
}

type Params = Record<string, any>

/**
 * HttpClient for API requests
 */
class HttpClient {
  axios: AxiosInstance

  config = {
    paramsSerializer: (params: Params) => stringify(params, { arrayFormat: 'brackets' }),
    validateStatus: (status: number) => status >= 200 && status < 300,
  }

  constructor() {
    this.axios = Axios.create(this.config)
    // @ts-ignore
    this.axios.interceptors.response.use(this.onSuccessResponse, this.onFailResponse)
  }

  async request<T = any>(
    action: HttpRequestAction,
    params?: Params | null,
    data?: Record<string, any> | null,
    headers: {
      [K in string]: string
    } = {},
  ): Promise<HttpResponseSuccess<T>> {
    const url = this.prepareURL(action)
    try {
      this.log(action, url, null, params, data)

      const requestConfig: AxiosRequestConfig = {
        url,
        method: action.method || 'GET',
        params,
        data,
        headers,
        baseURL: process.env.GATSBY_API_URL,
        withCredentials: true,
      }

      if (action.getCancel) {
        requestConfig.cancelToken = new Axios.CancelToken(action.getCancel)
      }

      const response = await this.axios.request<any, HttpResponseSuccess<T>>(
        requestConfig,
      )
      this.log(action, url, true, response)

      return response
    } catch (e) {
      this.log(
        action,
        url,
        false,
        e instanceof HttpResponseError && e.serialize ? e.serialize() : e,
      )
      throw e
    }
  }

  prepareURL = (action: HttpRequestAction) => action.path

  onSuccessResponse = (response: AxiosResponse<HttpSuccessResponseData>) => {
    return { success: true, responseData: response }
  }

  onFailResponse = (error: AxiosError<HttpFailResponseData>) => {
    const { response } = error
    const status = get(response, 'status', 0)
    const message = get(response, ['data', 'message'], '')

    const isCancelled = Axios.isCancel(error)
    const resultMessage = isCancelled ? error.message : message

    if (status === 401 && window.location.pathname !== '/') {
      window.location.href = '/'
    }

    throw new HttpResponseError(status, resultMessage, isCancelled)
  }

  log = (
    action: HttpRequestAction,
    path: string,
    success: boolean | null,
    ...args: Array<any>
  ) => {
    networkLog('HTTP', action.method || 'GET', path, success, ...args)
  }
}

export default new HttpClient()
