import React, { CSSProperties, ElementType, forwardRef, ReactNode, Ref } from 'react'
import classNames from 'classnames'
import styles from './BaseContainer.module.scss'

export type PageContainerVerticalIndents = 'md' | 'sm' | 'xs' | 'none'

export type PageContainerProps = {
  className?: string
  id?: string
  contentRef?: Ref<HTMLDivElement>
  contentClassName?: string
  children?: ReactNode
  Component?: ElementType
  vertical?: PageContainerVerticalIndents
  style?: CSSProperties
}

const BaseContainer = (props: PageContainerProps, ref: Ref<any>) => {
  const {
    className = '',
    id = '',
    contentRef,
    contentClassName = '',
    children,
    Component = 'div',
    vertical = 'md',
    style,
  } = props

  return (
    <Component
      ref={ref}
      id={id}
      className={classNames([
        styles.root,
        className,
        { md: styles.medium, sm: styles.small, xs: styles.smallest, none: styles.none }[
          vertical
        ],
      ])}
      style={style}
    >
      <div ref={contentRef} className={classNames([styles.content, contentClassName])}>
        {children}
      </div>
    </Component>
  )
}

export default forwardRef(BaseContainer)
